export default class User {
  constructor(
    customerID,
    portalID,
    userName,
    firstName,
    lastName,
    password,
    email,
    customerNumber,
    provider,
    isActive
  ) {
    // this.customerID = 0;
    // this.portalID = 0;
    // this.userName = '';
    // this.firstName = '';
    // this.lastName = '';
    // this.password = password;
    // this.customerEmail = customerEmail;
    // this.customerNumber = customerNumber;
    // this.provider = 1;
    // this.isActive = true;
    // this.createdDate = new Date().toISOString();
    // this.createdBy = 0;
    // this.lastActiveDate = new Date().toISOString();
    (this.email = email), (this.password = password);
  }
}
